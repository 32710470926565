<template>
    <ContentWrapper>
        <breadcrumb icon="fad fa-piggy-bank" :name="$t('wallet.view.action.make_withdraw.heading')"/>

        <div class="container animated-fast fadeInDown">
            <div class="card card-default">
                <div class="card-header">
                    {{$t('wallet.view.action.make_withdraw.heading')}}
                </div>

                <div class="card-body">
                    <div class="text-center">
                        <span class="" style="font-size:25px">{{$t('wallet.view.action.make_withdraw.amount')}}</span><br/>
                        <div class="mt-3">
                            <b-input class="text-center bg-transparent p-0 d-inline-block wd-sm text-right" style="font-size: 30px" v-model="withdraw_request.amount"
                                     @change="validateAmount"></b-input>
                            <span class="ml-3" style="font-size: 30px;">EUR</span></div>
                        <p class="my-3 text-muted">{{$t('wallet.view.action.make_withdraw.info')}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-xl-3">
                    <div class="card card-default animated-fast fadeInLeft  h-100">
                        <div class="card-header">{{$t('wallet.view.action.make_withdraw.method.heading')}}<i class="card-header-icon fad fa-exchange"></i></div>
                        <div class="card-body p-0">
                            <div class="list-group">
                                <a class="list-group-item list-group-item-action rounded-0"
                                   href="#"
                                   :class="activeTab === 'bank_transfer' ? 'active':''"
                                   @click.prevent="toggleTab('bank_transfer')">
                                  <i class="fad fa-address-card mr-2"></i>{{$t('wallet.view.action.make_withdraw.method.bank_transfer.label')}}
                                  <span class="small">
                                    <br/>{{ $t('wallet.view.action.make_withdraw.method.bank_transfer.target') }}
                                    <br/>{{ $t('wallet.view.action.make_withdraw.method.bank_transfer.fees') }}
                                    <br/>{{ $t('wallet.view.action.make_withdraw.method.bank_transfer.duration') }}
                                  </span>
                                </a>
                                <a class="list-group-item list-group-item-action rounded-0"
                                   href="#"
                                   :class="activeTab === 'bank_transfer_international' ? 'active':''"
                                   @click.prevent="toggleTab('bank_transfer_international')">
                                  <i class="fad fa-address-card mr-2"></i>{{$t('wallet.view.action.make_withdraw.method.bank_transfer_international.label')}}
                                  <span class="small">
                                    <br/>{{ $t('wallet.view.action.make_withdraw.method.bank_transfer_international.target') }}
                                    <br/>{{ $t('wallet.view.action.make_withdraw.method.bank_transfer_international.fees') }}
                                    <br/>{{ $t('wallet.view.action.make_withdraw.method.bank_transfer_international.duration') }}
                                  </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-9 animated-fast fadeInRight">
                    <div class="card card-default h-100" v-show="activeTab === 'bank_transfer'">
                        <div class="card-header bg-gray-lighter text-bold">{{$t('wallet.view.action.make_withdraw.method.bank_transfer.label')}}</div>
                        <div class="card-body">

                          <div class="mb-3" v-if="!status.bank_account">{{ $t('wallet.view.action.make_withdraw.method.bank_transfer.hint') }}</div>

                            <bank-account-form entity-slot="withdraw" ref="bankAccountForm" :hide-confirm-button="true"></bank-account-form>

                          <p>Bitte beachte, dass alle Provisionen und Guthaben unter Einhaltung der gesetzlichen Vorgaben ausgezahlt werden. Du bist selbst dafür verantwortlich, deine Steuerpflichten und Sozialversicherungsbeiträge, insbesondere im Hinblick auf die AHV in der Schweiz sowie vergleichbare Abgaben in der EU, zu klären und abzuführen. Wende dich bei Unsicherheiten gerne an deinen Steuerberater.</p>

                          <b-btn-toolbar class="justify-content-end">
                                <b-btn variant="primary" class="" @click="requestBankTransferWithdraw(false)">{{$t('wallet.view.action.make_withdraw.method.bitcoin.request')}}
                                </b-btn>
                            </b-btn-toolbar>

                        </div>
                    </div>
                    <div class="card card-default h-100" v-show="activeTab === 'bank_transfer_international'">
                        <div class="card-header bg-gray-lighter text-bold">{{$t('wallet.view.action.make_withdraw.method.bank_transfer.label')}}</div>
                        <div class="card-body">

                            <div class="mb-3">{{ $t('wallet.view.action.make_withdraw.method.bank_transfer_international.hint') }}</div>

                            <div class="mb-3" v-if="!status.bank_account">{{ $t('wallet.view.action.make_withdraw.method.bank_transfer.hint') }}</div>

                            <international-bank-account-form entity-slot="withdraw" ref="internationalBankAccountForm" :hide-confirm-button="true"></international-bank-account-form>

                          <p>Bitte beachte, dass alle Provisionen und Guthaben unter Einhaltung der gesetzlichen Vorgaben ausgezahlt werden. Du bist selbst dafür verantwortlich, deine Steuerpflichten und Sozialversicherungsbeiträge, insbesondere im Hinblick auf die AHV in der Schweiz sowie vergleichbare Abgaben in der EU, zu klären und abzuführen. Wende dich bei Unsicherheiten gerne an deinen Steuerberater.</p>


                          <b-btn-toolbar class="justify-content-end">
                                <b-btn variant="primary" class="" @click="requestBankTransferWithdraw(true)">{{$t('wallet.view.action.make_withdraw.method.bitcoin.request')}}
                                </b-btn>
                            </b-btn-toolbar>

                        </div>
                    </div>
                </div>
            </div>

            <b-row class="mt-3 animated-fast fadeInUp">
                <b-col cols="12">
                    <WithdrawBrowser></WithdrawBrowser>
                </b-col>
            </b-row>
        </div>

    </ContentWrapper>
</template>

<script>

    import WithdrawBrowser from '../../components/WithdrawBrowser';
    import BankAccountForm from "../../components/Forms/BankAccountForm";
    import InternationalBankAccountForm from "../../components/Forms/InternationalBankAccountForm";

    export default {
        components: {
            BankAccountForm,
            InternationalBankAccountForm,
            WithdrawBrowser
        },
        data() {
            return {
                activeTab: 'bank_transfer',
                withdraw_request: {
                    amount: 100,
                },
                status: {
                    verification_level: 0,
                    balance: 0,
                    is_request_active: false,
                    bank_account: null,
                }
            }
        },
        mounted() {
            this.$api.get('wallet/withdraw/status').then(response => {
                response.data.balance = parseInt(response.data.balance);
                this.withdraw_request.amount = parseInt(response.data.balance);
                this.status = response.data;
                this.validateAmount();

                if (response.data.verification_level < 1) {
                    this.$swal.fire('Verification Level too low', 'Verification level 1 required to withdraw.', 'warning');
                    this.$router.push({name: 'Settings', params: {menu: 'verification'}});
                    return;
                }

                this.toggleTab('bank_transfer');
                this.status = response.data;
            })
        },
        methods: {
            validateAmount() {
                let amount = parseInt(this.withdraw_request.amount);

                let min = 100;
                let max = 2000;

                if (this.activeTab === 'bank_transfer') {
                    min = 10;
                    max = 10000;
                }

                if (this.activeTab === 'bitcoin') {
                    min = 50 ;
                    max = 2000;
                }

                if (amount > this.status.balance) {
                    this.withdraw_request.amount = this.status.balance
                } else if (amount < min) {
                    this.withdraw_request.amount = min;
                } else if (amount > max) {
                    this.withdraw_request.amount = max;
                } else {
                    this.withdraw_request.amount = amount;
                }
            },
            toggleTab(tab) {
                 if (tab === 'bank_transfer' && this.status.verification_level < 2 && !this.$auth.hasPermissionTo('wallet.withdraw.ignore-verification')) {
                    this.$swal.fire('Verification Level too low', 'Verification level 2 required to withdraw via bank transfer.', 'warning');
                    this.$router.push({name: 'SettingsVerification'});
                } else {
                    if (tab === 'bank_transfer') {
                       if (this.status.balance < 10) {
                            this.$swal.fire('Balance to low', 'You need at least 10 EUR to request a withdraw with bank transfer', 'warning');
                        } else {
                            this.activeTab = tab;
                        }
                    } else {
                        this.activeTab = tab;
                    }
                }
            },
            requestBankTransferWithdraw(international = false) {
              let ref = (international ? this.$refs.internationalBankAccountForm : this.$refs.bankAccountForm)

              ref.submit().then(() => {
                    return this.$auth.requestVerfificationCode()
                }).then(code => {
                    return this.$api.post('wallet/withdraw/request-withdraw', {
                        type: 'bank_transfer' + (international ? '_international' : ''),
                        amount: this.withdraw_request.amount,
                        verification_code: code.verification_code,
                    })
                }).then(() => {
                    this.$swal.fire('Withdraw Requested', 'please be patient we will check your request in the next 3-4 days.', 'success');
                    this.$router.push({name: 'Wallet'});
                }).catch(() => {});
            }
        },
    }
</script>